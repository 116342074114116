/// StraightPipes
@import "../../../mixin.scss";

.blockCornerIcon {
  position: absolute;
  display: flex;
  z-index: 1;
  @include imgCornerSwitch;
}

.blockCornerIconOnslider {
  position: absolute;
  display: flex;
  z-index: 1;
  @include imgCornerSwitchOnSlider;
}

.containerStraightPipes {
  width: 10px;
  background-color: #e6702d;
  position: absolute;
  @include firstPipeleft;
}

.theTankMaxH {
  @include blockDubTheTankNone;
}

.blockDubTheTank {
  display: flex;
  gap: 15px;
  flex-direction: row;
  position: absolute;
  z-index: 1;
  @include blockDubTheTankMix;
}

.blockDoublePipes {
  position: absolute;
  gap: 10px;
  display: flex;
  flex-direction: row;
  height: 100%;
  @include doubleFooter;
}

.blockDoublePipesHeader {
  position: absolute;
  gap: 10px;
  display: flex;
  top: 0px;
  flex-direction: row;
  height: 100%;
  @include doubleFooter;
}

.blockDoublePipesHeaderS {
  //   z-index: 2;
  position: absolute;
  gap: 10px;
  display: flex;
  //   top: 300px;
  flex-direction: row;
  height: 100%;
  @include doubleFooterHeader;
}

.blockDoublePipesHeaderZigZag {
  position: relative;
  display: flex;
  @include pipeZigZagBig;
  img {
    position: absolute;
    top: 0px;
  }
}

.blockDoublePipesHeaderZigZagMini {
  position: relative;
  display: flex;
  @include pipeZigZagMini;
  img {
    position: absolute;
    top: 0px;
  }
}

.blockHeaderZigZag {
  display: flex;
  position: relative;
  img {
    display: flex;
    position: absolute;
  }
  div {
    top: 50px;
    position: absolute;
  }
}

.blockDoublePipesMain {
  position: absolute;
  gap: 10px;
  display: flex;
  flex-direction: row;
  height: 100%;
  @include doubleFooterMain;
}

.containerStraightPipesDouble {
  width: 10px;
  height: 100%;
  background-color: #e6702d;
}

.containerStraightPipesDoubleSec {
  width: 10px;
  height: 100%;

  background-color: #e6702d;
  //   position: absolute;
  //   left: 262px;
  // @include firstPipeleft;
}

.containerStraightPipesFooter {
  width: 10px;
  background-color: #e6702d;
  position: absolute;
  //   @include firstPipeleft;
  @include firstPipeleftFooter;
}

.containerStraightPipesSecond {
  width: 10px;
  background-color: #e6702d;
  position: absolute;

  //   left: 280px;
  @include firstPipeRight;
}

.containerStraightPipesSecondFotter {
  width: 10px;
  background-color: #e6702d;
  position: absolute;

  //   left: 280px;
  @include firstPipeRightFooter;
}

.blockTheTank {
  //   background-color: seagreen; // Dell
  display: flex;
  top: 273px;
  position: absolute;
  left: 180px;
  z-index: 2;
}

.blockTheTankSecond {
  top: 273px;

  //   background-color: seagreen; // Dell
  display: flex;
  position: absolute;
  left: 377px;
  z-index: 2;
}

.containerZigzagPipesPipes {
}

.blockZigzag {
  //   background-color: seagreen; // Dell
  display: flex;
  position: relative;
  align-self: start;
  top: 622px;
  left: 279.5px;
  //   width: 100px;
  //   height: 30px;
}

// .verticalZigZag {
//   position: absolute;
//   left: -9px;
//   transform: rotate(90deg);
// }

.wallZigZag {
  height: 10px;
  width: 50px;
  position: absolute;
  background-color: #e6702d;
}

.verticalZigZag {
  //   height: 10px;
  position: absolute;
  height: 10px;
  width: 10px;
  left: 0px;
  border: 0px;
  bottom: 0px;
  //   border: 10px;
  background-color: #e6702d;
}

.blockTheCanister {
  position: absolute;
  // background-color: darkblue; // Dell
  @include blockTheCanisterS;
}

.imgTheCanister {
  width: 100%;
  height: 100%;
}

.blockSizeTheTank {
  @include theTankSizeAdaptive;
  display: flex;
  img {
    display: flex;
    width: 100%;
    height: 100%;
  }
}

.blockPipesAll {
  position: absolute;
  clip-path: inset(2px 3px 7.2px 3px);
  @include positionImgTubesBlock;

  z-index: 2;
  img {
    // outline: none;
    // clip-path: inset(2px);
    // margin: -2px;

    // border: none;
    // padding: -3px;
    @include positionImgTubes;
  }
}
