@import "./variables";

@mixin wrapperContent {
  width: $widthMain8;

  @media (min-width: $checkW8) {
    width: $widthMain8;
  }
  @media (min-width: $checkW7) {
    width: $widthMain7;
  }
  @media (min-width: $checkW6) {
    width: $widthMain6;
  }
  @media (min-width: $checkW5) {
    width: $widthMain5;
  }
  @media (min-width: $checkW4) {
    width: $widthMain4;
  }
  @media (min-width: $checkW3) {
    width: $widthMain3;
  }
  @media (min-width: $checkW2) {
    width: $widthMain2;
  }
  @media (min-width: $checkW1) {
    width: $widthMain1;
  }
  @media (min-width: $checkW0) {
    width: $widthMain0;
  }
}

@mixin firstPipeleft {
  left: 5px;

  @media (min-width: $checkW8) {
    left: 5px;
  }
  @media (min-width: $checkW7) {
    left: 11px;
  }
  @media (min-width: $checkW6) {
    // left: 11px;
    left: 67px;
  }
  @media (min-width: $checkW5) {
    left: 52px;
  }
  @media (min-width: $checkW4) {
    left: 52px;
  }
  @media (min-width: $checkW3) {
    left: 70x;
  }
  @media (min-width: $checkW2) {
    left: 70px;
  }
  @media (min-width: $checkW1) {
    left: 101px;
  }
  @media (min-width: $checkW0) {
    left: 262px;
  }
}

@mixin firstPipeleftFooter {
  bottom: 501px;
  left: 5px;

  @media (min-width: $checkW8) {
    bottom: 501px;
    left: 5px;
  }
  @media (min-width: $checkW7) {
    left: 11px;
    bottom: 478px;
  }
  @media (min-width: $checkW6) {
    bottom: 510px;
    // bottom: 60px;
    left: 67px;
  }
  @media (min-width: $checkW5) {
    bottom: 610px;
    // bottom: 10px;
    left: 52px;
  }
  @media (min-width: $checkW4) {
    bottom: 756px;
    // bottom: 56px;
    left: 52px;
  }
  @media (min-width: $checkW3) {
    left: 70x;
    bottom: 756px;
  }
  @media (min-width: $checkW2) {
    left: 70px;
    bottom: 756px;
  }
  @media (min-width: $checkW1) {
    left: 101px;
    bottom: 756px;
  }
  @media (min-width: $checkW0) {
    left: 262px;
    bottom: 594px;
  }
}

@mixin doubleFooter {
  bottom: 501px;
  left: 5px;

  @media (min-width: $checkW8) {
    bottom: 501px;
    left: 5px;
  }
  @media (min-width: $checkW7) {
    left: 11px;
    bottom: 478px;
  }
  @media (min-width: $checkW6) {
    bottom: 510px;
    // bottom: 60px;
    left: 67px;
  }
  @media (min-width: $checkW5) {
    bottom: 610px;
    // bottom: 10px;
    left: 52px;
  }
  @media (min-width: $checkW4) {
    bottom: 756px;
    // bottom: 56px;
    left: 52px;
  }
  @media (min-width: $checkW3) {
    left: 70px;
    bottom: 756px;
  }
  @media (min-width: $checkW2) {
    left: 70px;
    bottom: 756px;
  }
  @media (min-width: $checkW1) {
    left: 101px;
    bottom: 756px;
  }
  @media (min-width: $checkW0) {
    left: 262px;
    bottom: 594px;
  }
}

@mixin doubleFooterHeader {
  // bottom: 501px;
  left: 5px;

  @media (min-width: $checkW8) {
    // bottom: 501px;
    left: 5px;
  }
  @media (min-width: $checkW7) {
    left: 11px;
    // bottom: 478px;
  }
  @media (min-width: $checkW6) {
    // bottom: 510px;
    // bottom: 60px;
    left: 67px;
    top: 339px;
  }
  @media (min-width: $checkW5) {
    // bottom: 610px;
    // bottom: 10px;
    left: 52px;
    top: 441px;
  }
  @media (min-width: $checkW4) {
    // left: 52px;
    left: 52px;
    top: 441px;
  }
  @media (min-width: $checkW3) {
    left: 70px;
    top: 441px;

    // bottom: 756px;
  }
  @media (min-width: $checkW2) {
    // left: 70px;
    left: 70px;
    top: 441px;
    // bottom: 756px;
  }
  @media (min-width: $checkW1) {
    left: 101px;
    top: 612px;
    // bottom: 756px;
  }
  @media (min-width: $checkW0) {
    left: 262px;
    top: 605px;
    // bottom: 594px;
  }
}

@mixin doubleFooterMain {
  left: 5px;

  @media (min-width: $checkW8) {
    left: 5px;
  }
  @media (min-width: $checkW7) {
    left: 11px;
  }
  @media (min-width: $checkW6) {
    left: 67px;
  }
  @media (min-width: $checkW5) {
    left: 52px;
  }
  @media (min-width: $checkW4) {
    left: 52px;
  }
  @media (min-width: $checkW3) {
    left: 70px;
  }
  @media (min-width: $checkW2) {
    left: 70px;
  }
  @media (min-width: $checkW1) {
    left: 101px;
  }
  @media (min-width: $checkW0) {
    left: 262px;
  }
}

@mixin firstPipeRight {
  left: 24px;

  @media (min-width: $checkW8) {
    left: 24px;
  }
  @media (min-width: $checkW7) {
    left: 30px;
  }
  @media (min-width: $checkW6) {
    // left: 30px;
    bottom: 510px;

    left: 86px;
  }
  @media (min-width: $checkW5) {
    left: 71px;
  }
  @media (min-width: $checkW4) {
    left: 71px;
  }
  @media (min-width: $checkW3) {
    left: 89x;
  }
  @media (min-width: $checkW2) {
    left: 89px;
  }
  @media (min-width: $checkW1) {
    left: 119px;
  }
  @media (min-width: $checkW0) {
    left: 280px;
  }
}

@mixin firstPipeRightFooter {
  left: 24px;

  @media (min-width: $checkW8) {
    left: 24px;
  }
  @media (min-width: $checkW7) {
    left: 30px;
  }
  @media (min-width: $checkW6) {
    left: 30px;
  }
  @media (min-width: $checkW5) {
    left: 71px;
  }
  @media (min-width: $checkW4) {
    left: 71px;
  }
  @media (min-width: $checkW3) {
    left: 89x;
  }
  @media (min-width: $checkW2) {
    left: 89px;
  }
  @media (min-width: $checkW1) {
    left: 119px;
  }
  @media (min-width: $checkW0) {
    left: 280px;
  }
}

@mixin blockTheCanisterS {
  left: 24px;
  width: 134px;
  height: 206px;
  bottom: 95px;

  @media (min-width: $checkW8) {
    left: 24px;
    width: 134px;
    height: 206px;
    bottom: 95px;
  }
  @media (min-width: $checkW7) {
    left: 30px;
    width: 134px;
    height: 206px;
    bottom: 75px;
  }
  @media (min-width: $checkW6) {
    left: 16px;
    width: 134px;
    height: 206px;
    // bottom: 75px;
    bottom: 314px;
  }
  @media (min-width: $checkW5) {
    left: 16px;
    width: 98px;
    height: 151px;
    bottom: 314px;
  }
  @media (min-width: $checkW4) {
    left: 16px;
    width: 98px;
    height: 151px;
    bottom: 314px;
  }
  @media (min-width: $checkW3) {
    left: 21px;
    width: 131px;
    height: 202px;
    bottom: 554px;
  }
  @media (min-width: $checkW2) {
    left: 21px;
    width: 131px;
    height: 202px;
    bottom: 554px;
  }
  @media (min-width: $checkW1) {
    left: 20px;
    width: 195px;
    height: 301px;
  }
  @media (min-width: $checkW0) {
    left: 180px;
    width: 194px;
    height: 299px;
    bottom: 594px;
  }
}

@mixin blockDubTheTankMix {
  left: 49.48px;
  top: 48px;

  @media (min-width: $checkW8) {
    left: 49.48px;
    top: 48px;
  }
  @media (min-width: $checkW7) {
    left: 65px;
    top: 48px;
  }
  @media (min-width: $checkW6) {
    // left: 65px;
    // top: 48px;
    left: 16px;
    top: 192px;
  }
  @media (min-width: $checkW5) {
    left: 16px;
    top: 192px;
  }
  @media (min-width: $checkW4) {
    left: 52px;
    top: 200px;
  }
  @media (min-width: $checkW3) {
    left: 20px;
    top: 200px;
  }
  @media (min-width: $checkW2) {
    left: 20px;
    top: 224px;
  }
  @media (min-width: $checkW1) {
    left: 20px;

    top: 273px;
  }
  @media (min-width: $checkW0) {
    left: 180px;
    top: 273px;
  }
}

@mixin blockDubTheTankNone {
  display: none;

  @media (min-width: $checkW8) {
    display: none;
  }
  @media (min-width: $checkW7) {
    display: none;
  }
  @media (min-width: $checkW6) {
    display: none;
  }
  @media (min-width: $checkW5) {
    display: none;
  }
  @media (min-width: $checkW4) {
    display: none;
  }
  @media (min-width: $checkW3) {
    display: none;
  }
  @media (min-width: $checkW2) {
    display: flex;
  }
  @media (min-width: $checkW1) {
    display: flex;
  }
  @media (min-width: $checkW0) {
    display: flex;
  }
}

@mixin pipeZigZagBig {
  display: none;

  @media (min-width: $checkW8) {
    display: none;
  }
  @media (min-width: $checkW7) {
    display: none;
  }
  @media (min-width: $checkW6) {
    display: none;
  }
  @media (min-width: $checkW5) {
    display: none;
  }
  @media (min-width: $checkW4) {
    display: none;
  }
  @media (min-width: $checkW3) {
    display: none;
  }
  @media (min-width: $checkW2) {
    display: none;
  }
  @media (min-width: $checkW1) {
    display: flex;
  }
  @media (min-width: $checkW0) {
    display: flex;
  }
}

@mixin pipeZigZagMini {
  display: flex;

  @media (min-width: $checkW8) {
    display: flex;
  }
  @media (min-width: $checkW7) {
    display: flex;
  }
  @media (min-width: $checkW6) {
    display: none;
  }
  @media (min-width: $checkW5) {
    display: none;
  }
  @media (min-width: $checkW4) {
    display: flex;
  }
  @media (min-width: $checkW3) {
    display: flex;
  }
  @media (min-width: $checkW2) {
    display: flex;
  }

  @media (min-width: $checkW1) {
    display: none;
  }
  @media (min-width: $checkW0) {
    display: none;
  }
}

@mixin theTankSizeAdaptive {
  width: 110.45px;
  height: 238.47px;

  @media (min-width: $checkW8) {
    width: 110.45px;
    height: 238.47px;
  }
  @media (min-width: $checkW7) {
    width: 110.45px;
    height: 238.47px;
  }
  @media (min-width: $checkW6) {
    width: 81.9px;
    height: 176.83px;
  }
  @media (min-width: $checkW5) {
    width: 81.9px;
    height: 176.83px;
  }
  @media (min-width: $checkW4) {
    width: 81.9px;
    height: 176.83px;
  }
  @media (min-width: $checkW3) {
    width: 110.45px;
    height: 238.47px;
  }
  @media (min-width: $checkW2) {
    width: 110.45px;
    height: 238.47px;
  }

  @media (min-width: $checkW1) {
    width: 175.5px;
    height: 378.93px;
  }
  @media (min-width: $checkW0) {
    width: 175.5px;
    height: 378.93px;
  }
}

@mixin positionImgTubesBlock {
  left: 6.5px;
  top: 48px;

  @media (min-width: $checkW8) {
    left: 6.5px;
    top: 48px;
  }
  @media (min-width: $checkW7) {
    left: 6.5px;
    top: 48px;
  }
  @media (min-width: $checkW6) {
    left: 12px;
    top: 48px;
  }
  @media (min-width: $checkW5) {
    left: 16px;
    top: 214px;
  }
  @media (min-width: $checkW4) {
    left: 16px;
    top: 226px;
  }
  @media (min-width: $checkW3) {
    left: 20px;
    top: 226px;
  }
  @media (min-width: $checkW2) {
    left: 20px;
    top: 226px;
  }
  @media (min-width: $checkW1) {
    left: 20px;
    top: 260px;
  }
  @media (min-width: $checkW0) {
    left: 180px;
    top: 265px;
  }
}

@mixin positionImgTubes {
  width: 304px;
  height: 10277px;
  background-image: url("../../public/img/Tubes/tunk/tubes-360.png");

  @media (min-width: $checkW8) {
    width: 304px;
    height: 10277px;
    background-image: url("../../public/img/Tubes/tunk/tubes-360.png");
  }
  @media (min-width: $checkW7) {
    width: 313px;
    height: 10335px;
    background-image: url("../../public/img/Tubes/tunk/tubes-390.svg");
  }
  @media (min-width: $checkW6) {
    width: 313px;
    height: 10335px;
    background-image: url("../../public/img/Tubes/tunk/tubes-390.svg");
  }
  @media (min-width: $checkW5) {
    width: 175px;
    height: 10335.88px;
    background-image: url("../../public/img/Tubes/tunk/tubes-834.png");
  }
  @media (min-width: $checkW4) {
    width: 236px;
    height: 7697px;
    background-image: url("../../public/img/Tubes/tunk/tubes-1200.png");
  }
  @media (min-width: $checkW3) {
    width: 236px;
    height: 7697px;
    background-image: url("../../public/img/Tubes/tunk/tubes-1200.png");
  }
  @media (min-width: $checkW2) {
    width: 236px;
    height: 7696.26px;
    background-image: url("../../public/img/Tubes/tunk/tubes-1200.png");
  }

  @media (min-width: $checkW1) {
    width: 375px;
    height: 8641.12px;
    background-image: url("../../public/img/Tubes/tunk/tubes-1600.png");
  }
  @media (min-width: $checkW0) {
    width: 375px;
    height: 8641.12px;
    border: 0px;
    background-image: url("../../public/img/Tubes/tunk/tubes-1920.png");
    // background-image: url("https://krasnayapolyanaresort.ru/html/widgets/pub-birds/assets/fullTubes/tubes_19201.svg");
    // background-image: url("https://krasnayapolyanaresort.ru/html/widgets/pub-birds/assets/fullTubes/tubes_1920_new.png");
  }
}

@mixin sizePhotoCraftBeer {
  width: 280px;
  height: 364px;

  @media (min-width: $checkW8) {
    width: 280px;
    height: 364px;
  }
  @media (min-width: $checkW7) {
    width: 306px;
    height: 397px;
  }
  @media (min-width: $checkW6) {
    width: 306px;
    height: 397px;
  }
  @media (min-width: $checkW5) {
    width: 582px;
    height: 423px;
  }
  @media (min-width: $checkW4) {
    width: 865px;
    height: 628px;
  }
  @media (min-width: $checkW3) {
    width: 865px;
    height: 628px;
  }
  @media (min-width: $checkW2) {
    width: 865px;
    height: 628px;
  }

  @media (min-width: $checkW1) {
    width: 1033px;
    height: 750px;
  }
  @media (min-width: $checkW0) {
    width: 1033px;
    height: 750px;
  }
}

@mixin showNavbarPC {
  display: none;

  @media (min-width: $checkW8) {
    display: none;
  }
  @media (min-width: $checkW7) {
    display: none;
  }
  @media (min-width: $checkW6) {
    display: none;
  }
  @media (min-width: $checkW5) {
    display: flex;
  }
  @media (min-width: $checkW4) {
    display: flex;
  }
  @media (min-width: $checkW3) {
    display: flex;
  }
  @media (min-width: $checkW2) {
    display: flex;
  }

  @media (min-width: $checkW1) {
    display: flex;
  }
  @media (min-width: $checkW0) {
    display: flex;
  }
}

@mixin showNavbarPcNAvbar {
  display: flex;

  @media (min-width: $checkW8) {
    display: flex;
  }
  @media (min-width: $checkW7) {
    display: flex;
  }
  @media (min-width: $checkW6) {
    display: flex;
  }
  @media (min-width: $checkW5) {
    display: none;
  }
  @media (min-width: $checkW4) {
    display: none;
  }
  @media (min-width: $checkW3) {
    display: none;
  }
  @media (min-width: $checkW2) {
    display: none;
  }

  @media (min-width: $checkW1) {
    display: none;
  }
  @media (min-width: $checkW0) {
    display: none;
  }
}

@mixin sizeTitelHeader {
  font-size: 48px;
  font-style: normal;
  font-weight: 280;
  line-height: 48px;

  @media (min-width: $checkW8) {
    font-size: 48px;
    font-style: normal;
    font-weight: 280;
    line-height: 48px;
  }
  @media (min-width: $checkW7) {
    font-size: 48px;
    font-style: normal;
    font-weight: 280;
    line-height: 48px;
  }
  @media (min-width: $checkW6) {
    font-size: 48px;
    font-style: normal;
    font-weight: 280;
    line-height: 48px;
  }
  @media (min-width: $checkW5) {
    font-size: 56px;
    font-style: normal;
    font-weight: 280;
    line-height: 56px;
  }
  @media (min-width: $checkW4) {
    font-size: 56px;
    font-style: normal;
    font-weight: 280;
    line-height: 56px;
  }
  @media (min-width: $checkW3) {
    font-size: 56px;
    font-style: normal;
    font-weight: 280;
    line-height: 56px;
  }
  @media (min-width: $checkW2) {
    font-size: 56px;
    font-style: normal;
    font-weight: 280;
    line-height: 56px;
  }

  @media (min-width: $checkW1) {
    font-size: 72px;
    font-style: normal;
    font-weight: 280;
    line-height: 72px;
  }
  @media (min-width: $checkW0) {
    font-size: 72px;
    font-style: normal;
    font-weight: 280;
    line-height: 72px;
  }
}

@mixin sizeDiscriptHeader {
  font-size: 24px;
  font-style: normal;
  font-weight: 280;
  line-height: 33px;

  @media (min-width: $checkW8) {
    font-size: 24px;
    font-style: normal;
    font-weight: 280;
    line-height: 33.6px;
  }
  @media (min-width: $checkW7) {
    font-size: 24px;
    font-style: normal;
    font-weight: 280;
    line-height: 33.6px;
  }
  @media (min-width: $checkW6) {
    font-size: 48px;
    font-style: normal;
    font-weight: 280;
    line-height: 48px;
  }
  @media (min-width: $checkW5) {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }
  @media (min-width: $checkW4) {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }
  @media (min-width: $checkW3) {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }
  @media (min-width: $checkW2) {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }

  @media (min-width: $checkW1) {
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 39.2px;
  }
  @media (min-width: $checkW0) {
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 39.2px;
  }
}

@mixin sizeLogoBeer {
  width: 151px;
  height: 201px;

  @media (min-width: $checkW8) {
    width: 151px;
    height: 201px;
  }
  @media (min-width: $checkW7) {
    width: 151px;
    height: 201px;
  }
  @media (min-width: $checkW6) {
    width: 151px;
    height: 201px;
  }
  @media (min-width: $checkW5) {
    width: 135.46px;
    height: 179px;
  }
  @media (min-width: $checkW4) {
    width: 135.46px;
    height: 179px;
  }
  @media (min-width: $checkW3) {
    width: 135.46px;
    height: 179px;
  }
  @media (min-width: $checkW2) {
    width: 135.46px;
    height: 179px;
  }

  @media (min-width: $checkW1) {
    width: 196px;
    height: 259px;
  }
  @media (min-width: $checkW0) {
    width: 196px;
    height: 259px;
  }
}

@mixin marinTopLogo {
  margin-top: 20px;
  gap: 40px;

  @media (min-width: $checkW8) {
    margin-top: 20px;
    gap: 40px;
  }
  @media (min-width: $checkW7) {
    margin-top: 64px;
    gap: 40px;
  }
  @media (min-width: $checkW6) {
    margin-top: 64px;
    gap: 40px;
  }
  @media (min-width: $checkW5) {
    margin-top: 48px;
    gap: 32px;
  }
  @media (min-width: $checkW4) {
    margin-top: 48px;
    gap: 32px;
  }
  @media (min-width: $checkW3) {
    margin-top: 146px;
    gap: 32px;
  }
  @media (min-width: $checkW2) {
    margin-top: 146px;
    gap: 32px;
  }

  @media (min-width: $checkW1) {
    margin-top: 146px;
    gap: 64px;
  }
  @media (min-width: $checkW0) {
    margin-top: 146px;
    gap: 64px;
  }
}

@mixin marinTopOpenPub {
  margin-top: 68px;
  gap: 24px;

  @media (min-width: $checkW8) {
    margin-top: 68px;
    gap: 24px;
  }
  @media (min-width: $checkW7) {
    margin-top: 68px;
    gap: 24px;
  }
  @media (min-width: $checkW6) {
    margin-top: 68px;
    gap: 24px;
  }
  @media (min-width: $checkW5) {
    margin-top: 292px;
    gap: 32px;
  }
  @media (min-width: $checkW4) {
    margin-top: 246px;

    gap: 64px;
  }
  @media (min-width: $checkW3) {
    margin-top: 246px;

    gap: 64px;
  }
  @media (min-width: $checkW2) {
    margin-top: 246px;

    gap: 64px;
  }

  @media (min-width: $checkW1) {
    margin-top: 246px;

    gap: 64px;
  }
  @media (min-width: $checkW0) {
    margin-top: 246px;
    gap: 64px;
  }
}
@mixin textSizeOpenPub {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.2px;

  @media (min-width: $checkW8) {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.2px;
  }
  @media (min-width: $checkW7) {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.2px;
  }
  @media (min-width: $checkW6) {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.2px;
  }
  @media (min-width: $checkW5) {
    font-size: 46px;
    font-style: normal;
    font-weight: 280;
    line-height: 55.2px;
  }
  @media (min-width: $checkW4) {
    font-size: 46px;
    font-style: normal;
    font-weight: 280;
    line-height: 55.2px;
  }
}

@mixin marginBottomMap {
  margin-bottom: 48px;

  @media (min-width: $checkW8) {
    margin-bottom: 48px;
  }
  @media (min-width: $checkW7) {
    margin-bottom: 48px;
  }
  @media (min-width: $checkW6) {
    margin-bottom: 48px;
  }
  @media (min-width: $checkW5) {
    margin-bottom: 49px;
  }
  @media (min-width: $checkW4) {
    margin-bottom: 32px;
  }
  @media (min-width: $checkW3) {
    margin-bottom: 32px;
  }
  @media (min-width: $checkW2) {
    margin-bottom: 32px;
  }

  @media (min-width: $checkW1) {
    margin-bottom: 64px;
  }
  @media (min-width: $checkW0) {
    margin-bottom: 80px;
  }
}

@mixin textTitelContent {
  font-size: 30px;
  font-weight: 400;
  line-height: 30px;

  @media (min-width: $checkW8) {
    font-size: 30px;
    font-weight: 400;
    line-height: 30px;
  }
  @media (min-width: $checkW7) {
    font-size: 30px;
    font-weight: 400;
    line-height: 30px;
  }
  @media (min-width: $checkW6) {
    font-size: 30px;
    font-weight: 400;
    line-height: 30px;
  }
  @media (min-width: $checkW5) {
    font-size: 56px;
    font-weight: 280;
    line-height: 56px;
  }
  @media (min-width: $checkW4) {
    font-size: 56px;
    font-weight: 280;
    line-height: 56px;
  }
  @media (min-width: $checkW3) {
    font-size: 56px;
    font-weight: 280;
    line-height: 56px;
  }
  @media (min-width: $checkW2) {
    font-size: 56px;
    font-weight: 280;
    line-height: 56px;
  }
  @media (min-width: $checkW1) {
    font-size: 72px;
    font-weight: 280;
    line-height: 72px;
  }
  @media (min-width: $checkW0) {
    font-size: 72px;
    font-weight: 280;
    line-height: 72px;
  }
}

@mixin marginLeftTitelContent {
  margin-left: 37.5px;

  @media (min-width: $checkW8) {
    margin-left: 37.5px;
  }
  @media (min-width: $checkW7) {
    margin-left: 37.5px;
  }
  @media (min-width: $checkW6) {
    margin-left: 37.5px;
  }
  @media (min-width: $checkW5) {
    margin-left: 149px;
  }
  @media (min-width: $checkW4) {
    margin-left: 217px;
  }
  @media (min-width: $checkW3) {
    margin-left: 217px;
  }
  @media (min-width: $checkW2) {
    margin-left: 217px;
  }

  @media (min-width: $checkW1) {
    margin-left: 283px;
  }
  @media (min-width: $checkW0) {
    margin-left: 443px;
  }
}

@mixin marginTitelFilosTop {
  margin-top: 367px;

  @media (min-width: $checkW8) {
    margin-top: 367px;
  }
  @media (min-width: $checkW7) {
    margin-top: 367px;
  }
  @media (min-width: $checkW6) {
    margin-top: 367px;
  }
  @media (min-width: $checkW5) {
    margin-top: 60px;
  }
  @media (min-width: $checkW4) {
    margin-top: 56px;
  }
  @media (min-width: $checkW3) {
    margin-top: 56px;
  }
  @media (min-width: $checkW2) {
    margin-top: 56px;
  }

  @media (min-width: $checkW1) {
    margin-top: 56px;
  }
  @media (min-width: $checkW0) {
    margin-top: 64px;
  }
}

@mixin marginLeftBlockDiscript {
  margin-left: 64px;

  @media (min-width: $checkW8) {
    margin-left: 64px;
  }
  @media (min-width: $checkW7) {
    margin-left: 68px;
  }
  @media (min-width: $checkW6) {
    margin-left: 68px;
  }
  @media (min-width: $checkW5) {
    margin-left: 216px;
  }
  @media (min-width: $checkW4) {
    margin-left: 315px;
  }
  @media (min-width: $checkW3) {
    margin-left: 315px;
  }
  @media (min-width: $checkW2) {
    margin-left: 315px;
  }
  @media (min-width: $checkW1) {
    margin-left: 547px;
  }
  @media (min-width: $checkW0) {
    margin-left: 707px;
  }
}

@mixin widthBlockDiscript {
  width: 279px;

  @media (min-width: $checkW8) {
    width: 279px;
  }
  @media (min-width: $checkW7) {
    width: 306px;
  }
  @media (min-width: $checkW6) {
    width: 306px;
  }
  @media (min-width: $checkW5) {
    width: 582px;
  }
  @media (min-width: $checkW4) {
    width: 767px;
  }
  @media (min-width: $checkW3) {
    width: 767px;
  }
  @media (min-width: $checkW2) {
    width: 767px;
  }
  @media (min-width: $checkW1) {
    width: 769px;
  }
  @media (min-width: $checkW0) {
    width: 769px;
  }
}

@mixin gapMarginBlockDiscriptPhilos {
  width: 279px;

  @media (min-width: $checkW8) {
    width: 279px;
  }
  @media (min-width: $checkW7) {
    width: 306px;
  }
  @media (min-width: $checkW6) {
    width: 306px;
  }
  @media (min-width: $checkW5) {
    margin-top: 40px;
    margin-bottom: 80px;
  }
  @media (min-width: $checkW4) {
    margin-top: 40px;
    margin-bottom: 80px;
  }
  @media (min-width: $checkW3) {
    margin-top: 40px;
    margin-bottom: 80px;
  }
  @media (min-width: $checkW2) {
    margin-top: 40px;
    margin-bottom: 80px;
  }
  @media (min-width: $checkW1) {
    margin-top: 40px;
    margin-bottom: 80px;
  }
  @media (min-width: $checkW0) {
    margin-top: 40px;
    margin-bottom: 80px;
  }
}

@mixin gribBlockDiscriptPhilosophy {
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(3, 1fr);
  margin-bottom: 40px;

  @media (min-width: $checkW8) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, 1fr);
    margin-bottom: 40px;
  }
  @media (min-width: $checkW7) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, 1fr);
    margin-bottom: 40px;
  }
  @media (min-width: $checkW6) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, 1fr);
    margin-bottom: 40px;
  }
  @media (min-width: $checkW5) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    margin-bottom: 64px;
  }
  @media (min-width: $checkW4) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
    margin-bottom: 80px;
  }
  @media (min-width: $checkW3) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
    margin-bottom: 80px;
  }
  @media (min-width: $checkW2) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
    margin-bottom: 80px;
  }
  @media (min-width: $checkW1) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
    margin-bottom: 80px;
  }
  @media (min-width: $checkW0) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
    margin-bottom: 80px;
  }
}

@mixin buttonContentSize {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  @media (min-width: $checkW8) {
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
  }
  @media (min-width: $checkW7) {
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
  }
  @media (min-width: $checkW6) {
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
  }
  @media (min-width: $checkW5) {
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
  }
  @media (min-width: $checkW4) {
    font-size: 20px;
    font-weight: 500;
    line-height: 24.2px;
  }
  @media (min-width: $checkW3) {
    font-size: 20px;
    font-weight: 500;
    line-height: 24.2px;
  }
  @media (min-width: $checkW2) {
    font-size: 20px;
    font-weight: 500;
    line-height: 24.2px;
  }
  @media (min-width: $checkW1) {
    font-size: 20px;
    font-weight: 500;
    line-height: 24.2px;
  }
  @media (min-width: $checkW0) {
    font-size: 20px;
    font-weight: 500;
    line-height: 24.2px;
  }
}

@mixin blockSizePhotoAtmosphere {
  margin-top: 32px;
  margin-left: 64px;

  grid-template-columns: repeat(1, 280px);
  grid-template-rows: repeat(4, 167px);

  @media (min-width: $checkW8) {
    margin-top: 32px;
    margin-left: 64px;

    grid-template-columns: repeat(1, 280px);
    grid-template-rows: repeat(4, 167px);
  }
  @media (min-width: $checkW7) {
    margin-top: 40px;

    margin-left: 68px;
    grid-template-columns: repeat(1, 306px);
    grid-template-rows: repeat(4, 182px);
  }
  @media (min-width: $checkW6) {
    margin-top: 40px;
    margin-left: 68px;

    grid-template-columns: repeat(1, 306px);
    grid-template-rows: repeat(4, 182px);
  }
  @media (min-width: $checkW5) {
    margin-top: 64px;
    margin-left: 216px;
    // margin-right: auto;
    grid-template-columns: repeat(1, 582px);
    grid-template-rows: repeat(4, 346px);
  }
  @media (min-width: $checkW4) {
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: repeat(4, 275px);
    grid-template-rows: repeat(1, 558px);
  }
  @media (min-width: $checkW3) {
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: repeat(4, 275px);
    grid-template-rows: repeat(1, 558px);
  }
  @media (min-width: $checkW2) {
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: repeat(4, 275px);
    grid-template-rows: repeat(1, 558px);
  }
  @media (min-width: $checkW1) {
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: repeat(4, 375px);
    grid-template-rows: repeat(1, 750px);
  }
  @media (min-width: $checkW0) {
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: repeat(4, 375px);
    grid-template-rows: repeat(1, 750px);
  }
}

@mixin textSizePhotoAtmosphere {
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;

  @media (min-width: $checkW8) {
    font-size: 18px;
    font-weight: 400;
    line-height: 25.2px;
  }
  @media (min-width: $checkW7) {
    font-size: 18px;
    font-weight: 400;
    line-height: 25.2px;
  }
  @media (min-width: $checkW6) {
    font-size: 18px;
    font-weight: 400;
    line-height: 25.2px;
  }
  @media (min-width: $checkW5) {
    font-size: 24px;
    font-weight: 400;
    line-height: 33.6px;
  }
  @media (min-width: $checkW4) {
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
  }
  @media (min-width: $checkW3) {
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
  }
  @media (min-width: $checkW2) {
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
  }
  @media (min-width: $checkW1) {
    font-size: 28px;
    font-weight: 400;
    line-height: 33.6px;
  }
  @media (min-width: $checkW0) {
    font-size: 28px;
    font-weight: 400;
    line-height: 33.6px;
  }
}

@mixin gridBlockAboutFoodButton {
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(2, 1fr);

  @media (min-width: $checkW8) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  @media (min-width: $checkW7) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  @media (min-width: $checkW6) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  @media (min-width: $checkW5) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }
  @media (min-width: $checkW4) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }
  @media (min-width: $checkW3) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }
  @media (min-width: $checkW2) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }
  @media (min-width: $checkW1) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }
  @media (min-width: $checkW0) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }
}

@mixin blockPhotoAbout {
  // width: 328px;
  gap: 8px;

  grid-template-columns: repeat(1, 328px);
  grid-template-rows: 328px 496px;

  @media (min-width: $checkW8) {
    gap: 8px;
    margin-top: 40px;

    grid-template-columns: repeat(1, 328px);
    grid-template-rows: 328px 496px;
  }
  @media (min-width: $checkW7) {
    gap: 8px;
    margin-top: 40px;
    grid-template-columns: repeat(1, 358px);
    grid-template-rows: 358px 541px;
  }
  @media (min-width: $checkW6) {
    gap: 8px;
    margin-top: 40px;
    grid-template-columns: repeat(1, 358px);
    grid-template-rows: 358px 541px;
  }
  @media (min-width: $checkW5) {
    gap: 16px;
    margin-top: 64px;
    grid-template-columns: repeat(1, 582px);
    grid-template-rows: 582px 881px;
  }
  @media (min-width: $checkW4) {
    width: 1200px;
    gap: 20px;
    margin-top: 60px;

    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 570px);
  }
  @media (min-width: $checkW3) {
    width: 1200px;
    gap: 20px;
    margin-top: 60px;

    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 570px);
  }
  @media (min-width: $checkW2) {
    gap: 20px;
    width: 1200px;
    margin-top: 60px;

    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 570px);
  }
  @media (min-width: $checkW1) {
    gap: 20px;
    margin-top: 60px;

    width: 1600px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 772px);
  }
  @media (min-width: $checkW0) {
    gap: 20px;
    width: 1560px;
    margin-top: 70px;

    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 772px);
  }
}

@mixin showMobh2Discript {
  display: none;

  @media (min-width: $checkW8) {
    display: none;
  }
  @media (min-width: $checkW7) {
    display: none;
  }
  @media (min-width: $checkW6) {
    display: none;
  }
  @media (min-width: $checkW5) {
    display: flex;
  }
  @media (min-width: $checkW4) {
    display: none;
  }
  @media (min-width: $checkW3) {
    display: none;
  }
  @media (min-width: $checkW2) {
    display: none;
  }
  @media (min-width: $checkW1) {
    display: none;
  }
  @media (min-width: $checkW0) {
    display: none;
  }
}

@mixin showMobVerisonDisrc {
  display: grid;

  @media (min-width: $checkW8) {
    display: grid;
  }
  @media (min-width: $checkW7) {
    display: grid;
  }
  @media (min-width: $checkW6) {
    display: grid;
  }
  @media (min-width: $checkW5) {
    display: grid;
  }
  @media (min-width: $checkW4) {
    display: none;
  }
  @media (min-width: $checkW3) {
    display: none;
  }
  @media (min-width: $checkW2) {
    display: none;
  }
  @media (min-width: $checkW1) {
    display: none;
  }
  @media (min-width: $checkW0) {
    display: none;
  }
}

@mixin modDiscriptTitelSize {
  font-size: 14px;
  font-weight: 280;

  @media (min-width: $checkW8) {
    font-size: 14px;
    font-weight: 280;
  }
  @media (min-width: $checkW7) {
    font-size: 14px;
    font-weight: 280;
  }
  @media (min-width: $checkW6) {
    font-size: 14px;
    font-weight: 280;
  }
  @media (min-width: $checkW5) {
    font-size: 18px;
    font-weight: 280;
  }
}

@mixin showPcVersion {
  display: none;

  @media (min-width: $checkW8) {
    display: none;
  }
  @media (min-width: $checkW7) {
    display: none;
  }
  @media (min-width: $checkW6) {
    display: none;
  }
  @media (min-width: $checkW5) {
    display: none;
  }
  @media (min-width: $checkW4) {
    display: grid;
  }
  @media (min-width: $checkW3) {
    display: grid;
  }
  @media (min-width: $checkW2) {
    display: grid;
  }
  @media (min-width: $checkW1) {
    display: grid;
  }
  @media (min-width: $checkW0) {
    display: grid;
  }
}
@mixin showDopInfaMainPhoto {
  display: none;

  @media (min-width: $checkW8) {
    display: none;
  }
  @media (min-width: $checkW7) {
    display: none;
  }
  @media (min-width: $checkW6) {
    display: none;
  }
  @media (min-width: $checkW5) {
    display: flex;
  }
  @media (min-width: $checkW4) {
    display: flex;
  }
  @media (min-width: $checkW3) {
    display: flex;
  }
  @media (min-width: $checkW2) {
    display: flex;
  }
  @media (min-width: $checkW1) {
    display: flex;
  }
  @media (min-width: $checkW0) {
    display: flex;
  }
}

@mixin magrginLeftAboutFootPhoto {
  margin-left: 16px;
  margin-right: auto;

  @media (min-width: $checkW8) {
    margin-left: 16px;
    margin-right: auto;
  }
  @media (min-width: $checkW7) {
    margin-left: 16px;
    margin-right: auto;
  }
  @media (min-width: $checkW6) {
    margin-left: 16px;
    margin-right: auto;
  }
  @media (min-width: $checkW5) {
    margin-left: 216px;
    margin-right: auto;
  }
  @media (min-width: $checkW4) {
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: $checkW3) {
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: $checkW2) {
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: $checkW1) {
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: $checkW0) {
    margin-left: auto;
    margin-right: auto;
  }
}

@mixin marginLeftImgMakingBeer {
  margin-left: 9px;

  @media (min-width: $checkW8) {
    margin-left: 9px;
  }
  @media (min-width: $checkW7) {
    margin-left: 10px;
  }
  @media (min-width: $checkW6) {
    margin-left: 15px;
  }
  @media (min-width: $checkW5) {
    margin-left: 69px;
  }
  @media (min-width: $checkW4) {
    margin-left: 86px;
  }
  @media (min-width: $checkW3) {
    margin-left: 90px;
  }
  @media (min-width: $checkW2) {
    margin-left: 90;
  }
  @media (min-width: $checkW1) {
    margin-left: 122px;
  }
  @media (min-width: $checkW0) {
    // margin-left: 377px;
    margin-left: 282px;
  }
}

@mixin switchIconBeerCorner {
  top: 313px;
  display: flex;
  position: absolute;
  left: 0px;
  clip-path: inset(1.4px 1.4px 1.4px 1.4px);
  img {
    width: 29px;
    height: 48px;
    background-image: url($cornerTab);
  }

  @media (min-width: $checkW8) {
    top: 313px;
    display: flex;
    position: absolute;
    left: 0px;
    clip-path: inset(1.4px 1.4px 1.4px 1.4px);

    img {
      width: 29px;
      height: 48px;
      background-image: url($cornerTab);
    }
  }
  @media (min-width: $checkW7) {
    top: 313px;
    display: flex;
    position: absolute;
    left: 0px;
    clip-path: inset(1.4px 1.4px 1.4px 1.4px);

    img {
      width: 29px;
      height: 48px;
      background-image: url($cornerTab);
    }
  }
  @media (min-width: $checkW6) {
    top: 313px;
    display: flex;
    position: absolute;
    left: 0px;
    clip-path: inset(1.4px 1.4px 1.4px 1.4px);

    img {
      width: 29px;
      height: 48px;
      background-image: url($cornerTab);
    }
  }
  @media (min-width: $checkW5) {
    top: 381px;
    display: flex;
    position: absolute;
    left: 0px;
    img {
      width: 149px;
      height: 54.5px;
      background-image: url("../../public/img/Tubes/beer/Vector 289.png");
    }
  }
  @media (min-width: $checkW4) {
    top: 381px;
    display: flex;
    position: absolute;
    left: 0px;
    img {
      width: 149px;
      height: 54.5px;
      background-image: url("../../public/img/Tubes/beer/Vector 289.png");
    }
  }
  @media (min-width: $checkW3) {
    top: 381px;
    display: flex;
    position: absolute;
    left: 0px;
    img {
      width: 149px;
      height: 54.5px;
      background-image: url("../../public/img/Tubes/beer/Vector 289.png");
    }
  }
  @media (min-width: $checkW2) {
    top: 381px;
    display: flex;
    position: absolute;
    left: 0px;
    img {
      width: 149px;
      height: 54.5px;
      background-image: url("../../public/img/Tubes/beer/Vector 289.png");
    }
  }
  @media (min-width: $checkW1) {
    top: 381px;
    display: flex;
    position: absolute;
    left: 0px;
    img {
      width: 149px;
      height: 54.5px;
      background-image: url("../../public/img/Tubes/beer/Vector 289.png");
    }
  }
  @media (min-width: $checkW0) {
    top: 381px;
    display: flex;
    position: absolute;
    left: 0px;
    img {
      width: 149px;
      height: 54.5px;
      background-image: url("../../public/img/Tubes/beer/Vector 289.png");
    }
  }
}

@mixin scrollBeerImgSwitch {
  // margin-left: 10px;
  width: 317px;
  height: 87.43px;
  background-image: url("../../public/img/MakingBeer/scroll/scrollbeerpc3.png");

  @media (min-width: $checkW8) {
    // margin-left: 10px;
    width: 317px;
    height: 87.43px;
    background-image: url("../../public/img/MakingBeer/scroll/scrollbeerpc3.png");
  }
  @media (min-width: $checkW7) {
    // margin-left: 17px;
    width: 317px;
    height: 87.43px;
    background-image: url("../../public/img/MakingBeer/scroll/scrollbeerpc3.png");
  }
  @media (min-width: $checkW6) {
    // margin-left: 17px;
    width: 317px;
    height: 87.43px;
    background-image: url("../../public/img/MakingBeer/scroll/scrollbeerpc3.png");
  }
  @media (min-width: $checkW5) {
    // margin-left: 74px;
    width: 348px;
    height: 87.43px;
    background-image: url("../../public/img/MakingBeer/scroll/scrollbeerpc2.png");
  }
  @media (min-width: $checkW4) {
    // margin-left: 95px;
    width: 592.2px;
    height: 72px;
    background-image: url("../../public/img/MakingBeer/scroll/scrollbeerpc.png");
  }
  @media (min-width: $checkW3) {
    // margin-left: 95px;
    width: 592.2px;
    height: 87.43px;
    background-image: url("../../public/img/MakingBeer/scroll/scrollbeerpc.png");
  }
  @media (min-width: $checkW2) {
    // margin-left: 95px;
    width: 592.2px;
    height: 87.43px;
    background-image: url("../../public/img/MakingBeer/scroll/scrollbeerpc.png");
  }
  @media (min-width: $checkW1) {
    // margin-left: 128px;
    width: 592.2px;
    height: 87.43px;
    background-image: url("../../public/img/MakingBeer/scroll/scrollbeerpc.png");
  }
  @media (min-width: $checkW0) {
    // margin-left: 377px;
    width: 592.2px;
    height: 87.43px;
    background-image: url("../../public/img/MakingBeer/scroll/scrollbeerpc.png");
  }
}

@mixin marginLeftImgMakingBeerScroll {
  margin-top: 16.77px;

  @media (min-width: $checkW8) {
    margin-top: 16.77px;
  }
  @media (min-width: $checkW7) {
    margin-top: 16.77px;
  }
  @media (min-width: $checkW6) {
    margin-top: 16.77px;
  }
  @media (min-width: $checkW5) {
    margin-top: 0px;
  }
  @media (min-width: $checkW4) {
    margin-top: 0px;
  }
  @media (min-width: $checkW3) {
    margin-top: 0px;
  }
  @media (min-width: $checkW2) {
    margin-top: 0px;
  }
  @media (min-width: $checkW1) {
    margin-top: 26.05px;
  }
  @media (min-width: $checkW0) {
    margin-top: 26.05px;
  }
}

@mixin showSliderSwipBeer {
  display: none;

  @media (min-width: $checkW8) {
    display: none;
  }
  @media (min-width: $checkW7) {
    display: none;
  }
  @media (min-width: $checkW6) {
    display: none;
  }
  @media (min-width: $checkW5) {
    display: none;
  }
  @media (min-width: $checkW4) {
    display: flex;
    margin-left: 133px;
  }
  @media (min-width: $checkW3) {
    display: flex;
    margin-left: 133px;
  }
  @media (min-width: $checkW2) {
    display: flex;
    margin-left: 133px;
  }
  @media (min-width: $checkW1) {
    display: flex;
    margin-left: 283px;
  }
  @media (min-width: $checkW0) {
    display: flex;
    margin-left: 443px;
  }
}

@mixin viewMobileFlex {
  display: flex;
  width: 301px;
  margin-left: 43px;
  margin-top: 24px;

  @media (min-width: $checkW8) {
    display: flex;
    width: 301px;
    margin-left: 43px;
    margin-top: 24px;
  }
  @media (min-width: $checkW7) {
    display: flex;
    width: 316px;
    margin-left: 58px;
    margin-top: 24px;
  }
  @media (min-width: $checkW6) {
    display: flex;
    margin-left: 58px;
    width: 316px;
    margin-top: 24px;
  }
  @media (min-width: $checkW5) {
    display: flex;
    width: 646px;
    margin-left: 152.35px;
    margin-top: 40px;
  }
  @media (min-width: $checkW4) {
    display: none;
    width: 646px;
    margin-left: 152.35px;
    margin-top: 40px;
    gap: 40.41px;
  }
  @media (min-width: $checkW3) {
    display: none;
  }
  @media (min-width: $checkW2) {
    display: none;
  }
  @media (min-width: $checkW1) {
    display: none;
  }
  @media (min-width: $checkW0) {
    display: none;
  }
}

@mixin contentBeerMobileVersion {
  flex-direction: column;
  gap: 7.41px;

  @media (min-width: $checkW8) {
    flex-direction: column;
    gap: 7.41px;
  }
  @media (min-width: $checkW7) {
    flex-direction: column;
    gap: 7.41px;
  }
  @media (min-width: $checkW6) {
    flex-direction: column;
    gap: 7.41px;
  }
  @media (min-width: $checkW5) {
    flex-direction: row;
    gap: 22px;
  }
  @media (min-width: $checkW4) {
    flex-direction: row;
    gap: 22px;
  }
}

@mixin textSizeMobileVBeer {
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;

  @media (min-width: $checkW8) {
    font-size: 24px;
    font-weight: 400;
    line-height: 33.6px;
  }
  @media (min-width: $checkW7) {
    font-size: 24px;
    font-weight: 400;
    line-height: 33.6px;
  }
  @media (min-width: $checkW6) {
    font-size: 24px;
    font-weight: 400;
    line-height: 33.6px;
  }
  @media (min-width: $checkW5) {
    font-size: 32px;
    font-weight: 280;
    line-height: 34.4px;
  }
  @media (min-width: $checkW4) {
    font-size: 40px;
    font-weight: 280;
    line-height: 48px;
  }
  @media (min-width: $checkW3) {
    font-size: 40px;
    line-height: 48px;
    font-weight: 280;
  }
  @media (min-width: $checkW2) {
    font-size: 40px;
    font-weight: 280;
    line-height: 48px;
  }
  @media (min-width: $checkW1) {
    font-size: 46px;
    font-weight: 280;
    line-height: 55.2px;
  }
  @media (min-width: $checkW0) {
    font-size: 46px;
    font-weight: 280;
    line-height: 55.2px;
  }
}

@mixin marginBlockDiscriptMobile {
  margin-top: 8px;
  margin-bottom: 32px;

  @media (min-width: $checkW8) {
    margin-top: 8px;
    margin-bottom: 32px;
  }
  @media (min-width: $checkW7) {
    margin-top: 8px;
    margin-bottom: 32px;
  }
  @media (min-width: $checkW6) {
    margin-top: 8px;
    margin-bottom: 32px;
  }
  @media (min-width: $checkW5) {
    margin-top: 16px;
    margin-bottom: 24px;
  }
  @media (min-width: $checkW4) {
    margin-top: 16px;
    margin-bottom: 24px;
  }
}

@mixin widthPhotoTakebeer {
  width: 288px;
  height: 360px;
  margin-top: 40px;

  @media (min-width: $checkW8) {
    width: 288px;
    height: 360px;
    margin-top: 40px;
  }
  @media (min-width: $checkW7) {
    width: 306px;
    height: 384px;
    margin-top: 40px;
  }
  @media (min-width: $checkW6) {
    width: 306px;
    height: 384px;
    margin-top: 40px;
  }
  @media (min-width: $checkW5) {
    width: 584px;
    height: 705px;
    margin-top: 64px;
  }
  @media (min-width: $checkW4) {
    width: 718px;
    height: 867px;
    margin-top: 80px;
  }
  @media (min-width: $checkW3) {
    width: 718px;
    height: 867px;
    margin-top: 80px;
  }
  @media (min-width: $checkW2) {
    width: 718px;
    height: 867px;
    margin-top: 80px;
  }
  @media (min-width: $checkW1) {
    width: 718px;
    height: 867px;
    margin-top: 80px;
  }
  @media (min-width: $checkW0) {
    width: 718px;
    height: 867px;
    margin-top: 80px;
  }
}

@mixin photoSizeMap {
  width: 280px;
  height: 319.74px;
  margin-top: 40px;

  @media (min-width: $checkW8) {
    width: 280px;
    height: 319.74px;
    margin-top: 40px;
  }
  @media (min-width: $checkW7) {
    width: 288px;
    height: 328.87px;
    margin-top: 40px;
  }
  @media (min-width: $checkW6) {
    width: 288px;
    height: 328.87px;
    margin-top: 40px;
  }
  @media (min-width: $checkW5) {
    margin-top: 64px;
    width: 582px;
    height: 465.37px;
  }
  @media (min-width: $checkW4) {
    margin-top: 64px;
    width: 865px;
    height: 691.67px;
  }
  @media (min-width: $checkW3) {
    margin-top: 64px;
    width: 865px;
    height: 691.67px;
  }
  @media (min-width: $checkW2) {
    margin-top: 64px;
    width: 865px;
    height: 691.67px;
  }
  @media (min-width: $checkW1) {
    margin-top: 64px;
    width: 1033px;
    height: 826px;
  }
  @media (min-width: $checkW0) {
    margin-top: 65px;
    width: 1033px;
    height: 826px;
  }
}

@mixin marginBottomEnd {
  margin-top: 610px;
  margin-bottom: 36px;

  @media (min-width: $checkW8) {
    margin-top: 610px;
    margin-bottom: 31px;
  }
  @media (min-width: $checkW7) {
    margin-top: 556px;
    margin-bottom: 40px;
  }
  @media (min-width: $checkW6) {
    margin-top: 532px;
    margin-bottom: 31px;
  }
  @media (min-width: $checkW5) {
    margin-top: 63.63px;
    margin-bottom: 84px;
  }
  @media (min-width: $checkW4) {
    margin-top: 80.33px;
    margin-bottom: 87px;
  }
  @media (min-width: $checkW3) {
    margin-top: 80.33px;
    margin-bottom: 87px;
  }
  @media (min-width: $checkW2) {
    margin-top: 80.33px;
    margin-bottom: 87px;
  }
  @media (min-width: $checkW1) {
    margin-top: 63px;
    margin-bottom: 83px;
  }
  @media (min-width: $checkW0) {
    margin-top: 64px;
    margin-bottom: 81px;
  }
}

@mixin gapColumnHome {
  gap: 54px;

  @media (min-width: $checkW8) {
    gap: 54px;
  }
  @media (min-width: $checkW7) {
    gap: 60px;
  }
  @media (min-width: $checkW6) {
    gap: 80px;
  }
  @media (min-width: $checkW5) {
    gap: 115px;
  }
  @media (min-width: $checkW4) {
    gap: 90px;
  }
  @media (min-width: $checkW3) {
    gap: 100px;
  }
  @media (min-width: $checkW2) {
    gap: 100px;
  }
  @media (min-width: $checkW1) {
    gap: 100px;
  }
  @media (min-width: $checkW0) {
    gap: 100px;
  }
}
@mixin heightSizeBlock {
  grid-template-rows: repeat(3, 159.34px);

  @media (min-width: $checkW8) {
    grid-template-rows: repeat(3, 159.34px);
  }
  @media (min-width: $checkW7) {
    grid-template-rows: repeat(3, 159.34px);
  }
  @media (min-width: $checkW6) {
    grid-template-rows: repeat(3, 175px);
  }
  @media (min-width: $checkW5) {
    grid-template-rows: repeat(3, 283px);
  }
  @media (min-width: $checkW4) {
    grid-template-rows: repeat(3, 283px);
  }
  @media (min-width: $checkW3) {
    grid-template-rows: repeat(3, 283px);
  }
  @media (min-width: $checkW2) {
    grid-template-rows: repeat(3, 283px);
  }
  @media (min-width: $checkW1) {
    grid-template-rows: repeat(3, 283px);
  }
  @media (min-width: $checkW0) {
    grid-template-rows: repeat(3, 283px);
  }
}

@mixin scrollBeerImgSwitchCraftBeer {
  width: 2200px;
  height: 801.23px;
  background-image: url("../../public/img/MakingBeer/beer/Property_1=mob.png");

  @media (min-width: $checkW8) {
    width: 2200px;
    height: 801.23px;
    background-image: url("../../public/img/MakingBeer/beer/Property_1=mob.png");
  }
  @media (min-width: $checkW7) {
    width: 2200px;
    height: 801.23px;
    background-image: url("../../public/img/MakingBeer/beer/Property_1=mob.png");
  }
  @media (min-width: $checkW6) {
    width: 2200px;
    height: 801.23px;
    background-image: url("../../public//img/MakingBeer/beer/Property_1=mob.png");
  }
  @media (min-width: $checkW5) {
    width: 2700px;
    height: 990px;
    background-image: url("../../public/img/MakingBeer/beer/Property_1=desk.png");
  }
  @media (min-width: $checkW4) {
    width: 2700px;
    height: 990px;
    background-image: url("../../public//img/MakingBeer/beer/Property_1=desk.png");
  }
  @media (min-width: $checkW3) {
    width: 2700px;
    height: 990px;
    background-image: url("../../public/img/MakingBeer/beer/Property_1=desk.png");
  }
  @media (min-width: $checkW2) {
    width: 2700px;
    height: 990px;
    background-image: url("../../public/img//MakingBeer/beer/Property_1=desk.png");
  }
  @media (min-width: $checkW1) {
    width: 2700px;
    height: 990px;
    background-image: url("../../public/img/MakingBeer/beer/Property_1=desk.png");
  }
  @media (min-width: $checkW0) {
    width: 2700px;
    height: 990px;
    // background-image: url("https://krasnayapolyanaresort.ru/html/widgets/pub-birds/assets/craftBeer/procescraftbeerdesk.png");
    background-image: url("../../public/img/MakingBeer/beer/Property_1=desk.png");
  }
}

@mixin marginTopBlockPhilosophy {
  margin-top: 24px;
  margin-bottom: 32px;

  @media (min-width: $checkW8) {
    margin-top: 24px;
    margin-bottom: 32px;
  }
  @media (min-width: $checkW7) {
    margin-top: 24px;
    margin-bottom: 32px;
  }
  @media (min-width: $checkW6) {
    margin-top: 24px;
    margin-bottom: 32px;
  }
  @media (min-width: $checkW5) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  @media (min-width: $checkW4) {
    margin-top: 56px;
    margin-bottom: 40px;
  }
  @media (min-width: $checkW3) {
    margin-top: 56px;
    margin-bottom: 40px;
  }
  @media (min-width: $checkW2) {
    margin-top: 56px;
    margin-bottom: 40px;
  }
  @media (min-width: $checkW1) {
    margin-top: 56px;
    margin-bottom: 40px;
  }
  @media (min-width: $checkW0) {
    margin-top: 56px;
    margin-bottom: 40px;
  }
}

@mixin gapBlockphilosophyContentText {
  height: 164px;

  @media (min-width: $checkW8) {
    height: 164px;
  }
  @media (min-width: $checkW7) {
    height: 164px;
  }
  @media (min-width: $checkW6) {
    height: 164px;
  }
  @media (min-width: $checkW5) {
    height: 164px;
  }
  @media (min-width: $checkW4) {
    height: 243px;
  }
  @media (min-width: $checkW3) {
    height: 243px;
  }
  @media (min-width: $checkW2) {
    height: 243px;
  }
  @media (min-width: $checkW1) {
    height: 243px;
  }
  @media (min-width: $checkW0) {
    height: 243px;
  }
}

@mixin marginTopDiscriptMakingBeer {
  margin-top: 24px;

  @media (min-width: $checkW8) {
    margin-top: 24px;
  }
  @media (min-width: $checkW7) {
    margin-top: 24px;
  }
  @media (min-width: $checkW6) {
    margin-top: 24px;
  }
  @media (min-width: $checkW5) {
    margin-top: 40px;
  }
  @media (min-width: $checkW4) {
    margin-top: 56px;
  }
  @media (min-width: $checkW3) {
    margin-top: 56px;
  }
  @media (min-width: $checkW2) {
    margin-top: 56px;
  }
  @media (min-width: $checkW1) {
    margin-top: 40px;
  }
  @media (min-width: $checkW0) {
    margin-top: 40px;
  }
}

@mixin topAbsTextFood {
  top: 82%;

  @media (min-width: $checkW8) {
    top: 82%;
  }
  @media (min-width: $checkW7) {
    top: 82%;
  }
  @media (min-width: $checkW6) {
    top: 82%;
  }
  @media (min-width: $checkW5) {
    top: 87%;
  }
  @media (min-width: $checkW4) {
    top: 87%;
  }
  @media (min-width: $checkW3) {
    top: 87%;
  }
  @media (min-width: $checkW2) {
    top: 87%;
  }
  @media (min-width: $checkW1) {
    top: 87%;
  }
  @media (min-width: $checkW0) {
    top: 87%;
  }
}

@mixin sizeSliderBlock {
  height: 548.63px;
  width: 853px;

  @media (min-width: $checkW8) {
    height: 548.63px;
    width: 853px;
  }
  @media (min-width: $checkW7) {
    height: 548.63px;
    width: 853px;
  }
  @media (min-width: $checkW6) {
    height: 548.63px;
    width: 853px;
  }
  @media (min-width: $checkW5) {
    height: 548.63px;
    width: 853px;
  }
  @media (min-width: $checkW4) {
    height: 548.63px;
    width: 853px;
  }
  @media (min-width: $checkW3) {
    height: 548.63px;
    width: 853px;
  }
  @media (min-width: $checkW2) {
    height: 548.63px;
    width: 853px;
  }
  @media (min-width: $checkW1) {
    height: 750px;
    width: 1060px;
  }
  @media (min-width: $checkW0) {
    height: 750px;
    width: 1060px;
  }
}

@mixin sizeSliderBlockPhoto {
  width: 346px;

  @media (min-width: $checkW8) {
    width: 346px;
  }
  @media (min-width: $checkW7) {
    width: 346px;
  }
  @media (min-width: $checkW6) {
    width: 346px;
  }
  @media (min-width: $checkW5) {
    width: 346px;
  }
  @media (min-width: $checkW4) {
    width: 346px;
  }
  @media (min-width: $checkW3) {
    width: 346px;
  }
  @media (min-width: $checkW2) {
    width: 346px;
  }
  @media (min-width: $checkW1) {
    width: 473px;
  }
  @media (min-width: $checkW0) {
    width: 473px;
  }
}

@mixin gapBlockSkiderPhoto {
  gap: 32px;

  @media (min-width: $checkW8) {
    gap: 32px;
  }
  @media (min-width: $checkW7) {
    gap: 32px;
  }
  @media (min-width: $checkW6) {
    gap: 32px;
  }
  @media (min-width: $checkW5) {
    gap: 32px;
  }
  @media (min-width: $checkW4) {
    gap: 32px;
  }
  @media (min-width: $checkW3) {
    gap: 32px;
  }
  @media (min-width: $checkW2) {
    gap: 32px;
  }
  @media (min-width: $checkW1) {
    gap: 77px;
  }
  @media (min-width: $checkW0) {
    gap: 77px;
  }
}

// @mixin marginTopDiscriptMakingBeer {
//   margin-top: 24px;

//   @media (min-width: $checkW8) {
//     margin-top: 24px;
//   }
//   @media (min-width: $checkW7) {
//     margin-top: 24px;
//   }
//   @media (min-width: $checkW6) {
//     margin-top: 24px;
//   }
//   @media (min-width: $checkW5) {
//     margin-top: 40px;
//   }
//   @media (min-width: $checkW4) {
//     margin-top: 56px;
//   }
//   @media (min-width: $checkW3) {
//     margin-top: 56px;
//   }
//   @media (min-width: $checkW2) {
//     margin-top: 56px;
//   }
//   @media (min-width: $checkW1) {
//     margin-top: 40px;
//   }
//   @media (min-width: $checkW0) {
//     margin-top: 40px;

//   }
// }

@mixin imgCornerSwitch {
  left: 7.2px;
  clip-path: inset(1.4px 1.4px 1.7px 2.2px);
  top: -20px;
  img {
    width: 29px;
    height: 47px;
    background-image: url($cornerMob);
  }

  @media (min-width: $checkW8) {
    left: 7.2px;
    clip-path: inset(1.4px 1.4px 1.7px 2.2px);
    top: -20px;
    img {
      width: 29px;
      height: 47px;
      background-image: url($cornerMob);
    }
  }
  @media (min-width: $checkW7) {
    left: 7.2px;
    clip-path: inset(1.4px 1.4px 1.5px 2.2px);
    top: -20px;

    img {
      width: 29px;
      height: 47px;
      background-image: url($cornerMob);
    }
  }
  @media (min-width: $checkW6) {
    left: 12.4px;
    clip-path: inset(2px 2px 1.5px 2.6px);
    top: -20px;

    img {
      width: 29px;
      height: 47px;
      background-image: url($cornerMob);
    }
  }
  @media (min-width: $checkW5) {
    left: 69px;
    clip-path: inset(3px 2.8px 1px 1px);
    top: -2px;

    img {
      width: 48px;
      height: 49px;
      background-image: url($cornerTab);
    }
  }
  @media (min-width: $checkW4) {
    left: 86px;
    clip-path: inset(3px 2.6px 1px 1px);
    top: 0px;

    img {
      width: 66px;
      height: 49px;
      background-image: url($cornerDes);
    }
  }
  @media (min-width: $checkW3) {
    left: 90px;
    clip-path: inset(1.7px);
    top: 0px;

    img {
      width: 66px;
      height: 49px;
      background-image: url($cornerDes);
    }
  }
  @media (min-width: $checkW2) {
    left: 90px;
    clip-path: inset(2.5px);
    top: -4px;

    img {
      width: 66px;
      height: 49px;
      background-image: url($cornerDes);
    }
  }
  @media (min-width: $checkW1) {
    left: 122px;
    clip-path: inset(2.5px);
    top: 0px;
    img {
      width: 66px;
      height: 49px;
      background-image: url($cornerDes);
    }
  }
  @media (min-width: $checkW0) {
    left: 283px;
    clip-path: inset(2.5px);
    top: 0px;
    img {
      width: 66px;
      height: 49px;
      background-image: url($cornerDes);
    }
  }
}

@mixin imgCornerSwitchOnSlider {
  clip-path: inset(1.4px 1.4px 1.7px 1.95px);
  left: -35.6px;
  top: -25px;
  display: flex;
  img {
    width: 29px;
    height: 47px;
    background-image: url($cornerMob);
  }

  @media (min-width: $checkW8) {
    clip-path: inset(1.4px 1.4px 1.7px 1.95px);
    left: -35.6px;
    top: -25px;
    display: flex;
    img {
      width: 29px;
      height: 47px;
      background-image: url($cornerMob);
    }
  }
  @media (min-width: $checkW7) {
    left: -50px;
    clip-path: inset(1.4px 1.4px 1.5px 1.5px);
    top: -25px;
    display: flex;
    img {
      width: 29px;
      height: 47px;
      background-image: url($cornerMob);
    }
  }
  @media (min-width: $checkW6) {
    left: -45.5px;
    clip-path: inset(2px 2px 1.5px 2.2px);
    top: -26px;
    display: flex;

    img {
      width: 29px;
      height: 47px;
      // background-image: url("https://krasnayapolyanaresort.ru/html/widgets/pub-birds/assets/corner/mob.png");
      background-image: url($cornerMob);
    }
  }
  @media (min-width: $checkW5) {
    left: 69px;
    clip-path: inset(1px);
    top: -2px;
    display: none;

    img {
      width: 48px;
      height: 49px;
      // background-image: url("https://krasnayapolyanaresort.ru/html/widgets/pub-birds/assets/corner/tab.png");
      background-image: url($cornerTab);
    }
  }
  @media (min-width: $checkW4) {
    left: 86px;
    clip-path: inset(1px);
    top: 0px;
    display: none;

    img {
      width: 66px;
      height: 49px;
      // background-image: url("https://krasnayapolyanaresort.ru/html/widgets/pub-birds/assets/corner/desktop.png");
      background-image: url($cornerDes);
    }
  }
  @media (min-width: $checkW3) {
    left: 90px;
    clip-path: inset(1px);
    top: 0px;
    display: none;

    img {
      width: 66px;
      height: 49px;
      // background-image: url("https://krasnayapolyanaresort.ru/html/widgets/pub-birds/assets/corner/desktop.png");
      background-image: url($cornerDes);
    }
  }
  @media (min-width: $checkW2) {
    left: 90px;
    clip-path: inset(1px);
    top: -4px;
    display: none;

    img {
      width: 66px;
      height: 49px;
      // background-image: url("https://krasnayapolyanaresort.ru/html/widgets/pub-birds/assets/corner/desktop.png");
      background-image: url($cornerDes);
    }
  }
  @media (min-width: $checkW1) {
    left: 122px;
    clip-path: inset(1px);
    top: 0px;
    display: none;

    img {
      width: 66px;
      height: 49px;
      // background-image: url("https://krasnayapolyanaresort.ru/html/widgets/pub-birds/assets/corner/desktop.png");
      background-image: url($cornerDes);
    }
  }
  @media (min-width: $checkW0) {
    left: 283px;
    clip-path: inset(1px);
    top: 0px;
    display: none;

    img {
      width: 66px;
      height: 49px;
      // background-image: url("https://krasnayapolyanaresort.ru/html/widgets/pub-birds/assets/corner/desktop.png");
      background-image: url($cornerDes);
    }
  }
}

@mixin textSizeTielPopup {
  padding: 120px 30px;
  margin: 0px;
  h1 {
    color: #fff;
    font-size: 30px;
    font-weight: 400;
  }
  span {
    a {
      color: #de6521;
      font-size: 30px;
      font-weight: 280;
    }
  }
  h2 {
    color: #fff;
    font-size: 20px;
    font-weight: 4000;
  }

  @media (min-width: $checkW8) {
    padding: 120px 30px;
    margin: 0px;

    h1 {
      color: #fff;
      font-size: 30px;
      font-weight: 400;
    }
    span {
      a {
        color: #de6521;
        font-size: 30px;
        font-weight: 280;
      }
    }
    h2 {
      color: #fff;
      font-size: 20px;
      font-weight: 4000;
    }
  }
  @media (min-width: $checkW7) {
    padding: 120px 30px;
    margin: 0px;
    h1 {
      color: #fff;
      font-size: 30px;
      font-weight: 400;
    }
    span {
      a {
        color: #de6521;
        font-size: 30px;
        font-weight: 280;
      }
    }
    h2 {
      color: #fff;
      font-size: 20px;
      font-weight: 4000;
    }
  }
  @media (min-width: $checkW6) {
    padding: 120px 30px;
    margin: 0px;
    h1 {
      color: #fff;
      font-size: 30px;
      font-weight: 400;
    }
    span {
      a {
        color: #de6521;
        font-size: 30px;
        font-weight: 280;
      }
    }
    h2 {
      color: #fff;
      font-size: 20px;
      font-weight: 4000;
    }
  }
  @media (min-width: $checkW5) {
    padding: 80px 60px;
    margin-left: 122px;
    margin-right: 122px;
    h1 {
      color: #fff;
      font-size: 30px;
      font-weight: 300;
    }
    span {
      a {
        color: #de6521;
        font-size: 30px;
        font-weight: 280;
      }
    }

    h2 {
      color: #fff;
      font-size: 20px;
      font-weight: 4000;
    }
  }
  @media (min-width: $checkW4) {
    padding: 120px 104px;
    margin-left: 122px;
    margin-right: 122px;
    h1 {
      color: #fff;
      font-size: 48px;
      font-weight: 280;
    }
    span {
      a {
        color: #de6521;
        font-size: 48px;
        font-weight: 280;
      }
    }
    h2 {
      color: #fff;
      font-size: 28px;
      font-weight: 4000;
    }
  }
  @media (min-width: $checkW3) {
    padding: 120px 104px;
    margin-left: 122px;
    margin-right: 122px;
    h1 {
      color: #fff;
      font-size: 48px;
      font-weight: 280;
    }
    span {
      a {
        color: #de6521;
        font-size: 48px;
        font-weight: 280;
      }
    }
    h2 {
      color: #fff;
      font-size: 28px;
      font-weight: 4000;
    }
  }
  @media (min-width: $checkW2) {
    padding: 120px 104px;
    margin-left: 122px;
    margin-right: 122px;
    h1 {
      color: #fff;
      font-size: 48px;
      font-weight: 280;
    }
    span {
      a {
        color: #de6521;
        font-size: 48px;
        font-weight: 280;
      }
    }
    h2 {
      color: #fff;
      font-size: 28px;
      font-weight: 4000;
    }
  }
  @media (min-width: $checkW1) {
    padding: 120px 104px;
    margin-left: 122px;
    margin-right: 122px;
    h1 {
      color: #fff;
      font-size: 48px;
      font-weight: 280;
    }
    span {
      a {
        color: #de6521;
        font-size: 48px;
        font-weight: 280;
      }
    }
    h2 {
      color: #fff;
      font-size: 28px;
      font-weight: 4000;
    }
  }
  @media (min-width: $checkW0) {
    padding: 120px 104px;
    margin-left: 122px;
    margin-right: 122px;
    h1 {
      color: #fff;
      font-size: 48px;
      font-weight: 280;
    }
    span {
      a {
        color: #de6521;
        font-size: 48px;
        font-weight: 280;
      }
    }
    h2 {
      color: #fff;
      font-size: 28px;
      font-weight: 4000;
    }
  }
}

@mixin headerMixinImg {
  // width: 360px;
  // height: 700px;
  background-image: url("../../public/img/Header/mixin/header_360.png");

  @media (min-width: $checkW8) {
    // width: 360px;
    // height: 700px;
    background-image: url("../../public/img/Header/mixin/header_360.png");
  }
  @media (min-width: $checkW7) {
    // width: 390px;
    // height: 758px;
    background-image: url("../../public/img/Header/mixin/header_390.png");
  }
  @media (min-width: $checkW6) {
    // width: 390px;
    // height: 758px;
    background-image: url("../../public/img/Header/mixin/header_390.png");
  }
  @media (min-width: $checkW5) {
    // width: 814px;
    // height: 1194px;
    background-image: url("../../public/img/Header/mixin/header_840.png");
  }
  @media (min-width: $checkW4) {
    // width: 1200px;
    // height: 627px;
    background-image: url("../../public/img/Header/mixin/header_1160.png");
  }
  @media (min-width: $checkW3) {
    // width: 1200px;
    // height: 627px;
    background-image: url("../../public/img/Header/mixin/header_1160.png");
  }
  @media (min-width: $checkW2) {
    // width: 1600px;
    // height: 836px;
    background-image: url("../../public/img/Header/mixin/header_1600.png");
  }
  @media (min-width: $checkW1) {
    // width: 1600px;
    // height: 836px;
    background-image: url("../../public/img/Header/mixin/header_1600.png");
  }
  @media (min-width: $checkW0) {
    width: 100%;
    object-fit: cover;
    height: 100%;
    background-repeat: no-repeat;
    background-image: url("../../public/img/Header/mixin/header_1920.jpg");
  }
}

@mixin sizeButtonHeader {
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  @media (min-width: $checkW8) {
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
  }
  @media (min-width: $checkW7) {
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
  }
  @media (min-width: $checkW6) {
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
  }
  @media (min-width: $checkW5) {
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
  }
  @media (min-width: $checkW4) {
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
  }
  @media (min-width: $checkW3) {
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
  }
  @media (min-width: $checkW2) {
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
  }

  @media (min-width: $checkW1) {
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
  }
  @media (min-width: $checkW0) {
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
  }
}

@mixin widthTitelSize {
  max-width: 301px;
  @media (min-width: $checkW8) {
    max-width: 301px;
  }
  @media (min-width: $checkW7) {
    max-width: 301px;
  }
  @media (min-width: $checkW6) {
    max-width: 320px;
  }
  @media (min-width: $checkW5) {
    max-width: 649px;
  }
  @media (min-width: $checkW4) {
    max-width: 950px;
  }
  @media (min-width: $checkW3) {
    max-width: 950px;
  }
  @media (min-width: $checkW2) {
    max-width: 950px;
  }

  @media (min-width: $checkW1) {
    max-width: 1297px;
  }
  @media (min-width: $checkW0) {
    max-width: 1297px;
  }
}
