@import "../../../mixin.scss";
.wrapper {
  z-index: 4;
  top: 0px;
  left: 0px;
  background-color: #20253c;
  display: flex;
  position: fixed;
  width: 100%;
  flex-direction: row;
  height: 100vh;
  gap: 24px;
  overflow-y: hidden;
  //   align-items: center;
  //   justify-content: center;
}

.blockLeftButton {
  @include showDopInfaMainPhoto;
  z-index: 2;
  position: absolute;
  left: 64px;
  height: 100%;
  display: flex;
  align-items: center;
  //   background-color: darkcyan; // Dell
}

.blockRightButton {
  @include showDopInfaMainPhoto;
  z-index: 2;

  right: 64px;
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  //   background-color: darkcyan; // Dell
}

.blockContentSwiper {
  display: flex;
  //   flex: 1;
  max-width: 1920px;
  //   background-color: chartreuse; //Dell
}
.blockSliderSwiperContent {
  display: block;
  align-self: start;
  //   flex: 1;
  //   max-width: 1920px;
  width: 100%;
  height: 100%;
  //   background-color: aqua; //Dell
}

.sliderPhoto {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.buttonClose {
  cursor: pointer;
  z-index: 3;
  display: flex;
  position: absolute;
  top: 32px;
  right: 32px;
}
