@import "../../variables";
@import "../../mixin.scss";

.container {
  // background-color: rosybrown; // Dell
  position: relative;
  flex-direction: column;
  display: flex;
}

.blockTitle {
  display: flex;
  @include marginTitelFilosTop;
  @include widthTitelSize;
  height: fit-content;
  @include marginLeftTitelContent;
}

.contentPhilosophy {
  display: flex;
  flex-direction: column;
  // width: 769px;
  // width: auto;
  // flex: 0;
  // margin-left: 707px;

  @include widthBlockDiscript;
  @include marginLeftBlockDiscript;
  // background-color: rgb(30, 51, 51); // Dell
}

.blockDiscript {
  display: flex;
  flex-wrap: wrap;
  @include marginTopBlockPhilosophy;

  // background-color: forestgreen; // Dell
}

.blockContentBear {
  box-sizing: border-box;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  justify-content: space-between;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  border: 2px solid #c3692b;
  white-space: pre-wrap;
  // gap: 82px;
  @include gapBlockphilosophyContentText;
}

.contentBlockBear {
  box-sizing: border-box;
  display: grid;
  column-gap: 20px;
  row-gap: 20px;
  @include gribBlockDiscriptPhilosophy;
  // background-color: cadetblue; // Dell
}

.imgCaraftBear {
  @include sizePhotoCraftBeer;
  object-fit: cover;
}

/// TEXT
.textTitle {
  word-wrap: break-word;
  color: #fff;
  @include textTitelContent;
}

.textDiscriptPhilosophy {
  color: #fff;
  line-height: 28px;
  font-size: 20px;
  font-weight: 400;
}

.textTitelContentBear {
  color: #fff;
  line-height: 25.2px;
  font-size: 18px;
  font-weight: 400;
}

.textDiscrContentBear {
  color: #fff;
  font-size: 15px;
  line-height: 21px;
  font-weight: 400;
}
