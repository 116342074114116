* {
  margin: 0px;
  padding: 0px;
  /* box-sizing: border-box; */
  /* test */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
}

button {
  cursor: pointer;
}
