// * {
//   font-family: $fontFamilyGlobal;
// }

@font-face {
  font-family: "TT Fors Trial Variable";
  // src: url('fonts/EntezareZohoor2.eot'),;
  src: 
//   url("../fonts/TT Fors Trial Variable.ttf"),
    url("../fonts/TT\ Fors\ Trial\ Variable.ttf"),
    // url("../fonts/TT Fors Trial Light.ttf"),
    url("../fonts/TT\ Fors\ Trial\ Light.ttf"),
    // url("../fonts/TT Fors Trial Regular.ttf");
    url("../fonts/TT\ Fors\ Trial\ Regular.ttf");
  // font-weight: normal;
  // font-style: normal;
}
* {
  font-family: "TT Fors Trial Variable";
}
// $fontFamilyGlobal: "TT-Fors-Display-Trial-Variable";

//PC width 1920
$checkW0: 1920px;
$widthMain0: 1920px;

//PC width 1660
// $checkW1: 1660px;
$checkW1: 1600px;
$widthMain1: 1600px;

//100k_1440
$checkW2: 1440px;
$widthMain2: 1440px;

//100k_1272
$checkW3: 1272px;
$widthMain3: 1272px;

//100k_1160
$checkW4: 1160px;
$widthMain4: 1160px;

//100k_880
$checkW5: 840px;
$widthMain5: 840px;

//100k_598
$checkW6: 598px;
$widthMain6: 598px;

//100k_392
$checkW7: 392px;
$widthMain7: 392px;

//100k_360
$checkW8: 360px;
$widthMain8: 360px;

// corner  in SCSS
$cornerDes: "../../public/img/Tubes/corner/Desktop.png";
$cornerTab: "../../public/img/Tubes/corner/Tab.png";
$cornerMob: "../../public/img/Tubes/corner/Mob.png";
