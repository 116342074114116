@import "../../mixin.scss";
.container {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: start;
  // overflow: hidden;
  // margin-top: 64px;
  // margin-bottom: 81px;
}

.blockTitle {
  display: flex;
  // padding-left: 443px;
  align-self: start;
  @include marginLeftTitelContent;
  // background-color: darkblue; // Dell
}

.blockDiscript {
  margin-top: 27px;
  // width: 375px;
  @include marginLeftBlockDiscript;
  // height: fit-content;
  @include widthBlockDiscript;

  display: flex;
  flex-direction: column;
  gap: 22px;
  align-self: flex-start;
  // align-self: start;
  // margin-left: 707px;
}

.blockContentDiscript {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.blockImgMap {
  @include photoSizeMap;
  @include marginLeftBlockDiscript;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.blockEndFooter {
  display: flex;
  @include marginLeftBlockDiscript;
  @include marginBottomEnd;
}

// TEXT

.textTitel {
  color: #fff;
  @include textTitelContent;
}

.textTitelDiscript {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}

.textDiscriptW {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
}
.textDiscriptR {
  z-index: 3;
  text-decoration: none;
  color: #e6702d;
  line-height: 21px;
  font-size: 15px;
  font-weight: 400;
}

.textblockEndFooter {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
}

.mapStyles {
  width: 100%;
  height: 100%;

  // -ms-filter: grayscale(100);
  // -webkit-filter: grayscale(100);
  // -moz-filter: grayscale(100);
  // -o-filter: grayscale(100);
  // filter: grayscale(100);
  // filter: invert(100%);
  // -ms-filter: invert(100%);
  // -webkit-filter: invert(100%);
  // -moz-filter: invert(100%);
  // -o-filter: invert(100%);
}
