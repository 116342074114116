@import "../../mixin.scss";
.container {
  // background-color: aqua; // Dell
  // border: 1px solid red;
  // height: 800px;
  display: flex;
  flex-direction: column;
  position: relative;
  // margin-top: 64px;
  // margin-bottom: 49px;
}

.blockTitle {
  display: flex;
  // background-color: rgb(99, 179, 99); // Dell
  // position: relative;
  flex-wrap: wrap;
  // align-items: center;
  // justify-content: center;
  // height: 80px;
  @include marginLeftTitelContent;
  // background-color: darkblue; // Dell
}

.blockDiscript {
  // margin-top: 40px;
  @include marginTopDiscriptMakingBeer;
  display: flex;
  // margin-left: auto;
  // margin-right: auto;
  @include marginLeftBlockDiscript;

  flex-direction: column;
  gap: 40px;
  // background-color: rgb(87, 116, 116);
}

.buttonRentTab {
  z-index: 3;
  cursor: pointer;
  border: none;
  background-color: #e6702d;
  padding: 24px 32px 24px 32px;
  color: #fff;
  @include buttonContentSize;
  // font-size: 20px;
  // font-weight: 500;
  align-self: start;
  border: 0px;
}

.contentVariantBar {
  // background-color: darkcyan; // Dell
  // width: 375px;

  // height: 750px;
  cursor: pointer;

  position: relative;
}

.blockVarintBar {
  // background-color: wheat; // Dell
  // width: ;
  // height: 750px;
  // display: flex;
  // flex-direction: row;
  @include blockSizePhotoAtmosphere;
  // grid-template-columns: repeat(4, 375px);
  // grid-template-rows: repeat(1, 750px);
  box-sizing: border-box;
  display: grid;
  gap: 20px;
  padding: 20px 0px 20px 0px;
  align-self: start;

  z-index: 3;
}

.blockAbsText {
  // background-color: #e6702d; //Dell
  // display: inline-flex;
  position: absolute;
  // top: 87%;
  @include topAbsTextFood;
}

.blockTextVarintBar {
  // background-color: gray; // Dell
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 72.07%,
    rgba(0, 0, 0, 0.9) 100%
  );
  // display: inline-flex;
  display: flex;
  // flex-direction: column;
  // align-items: stretch;
  // justify-content: end;
  // min-height: 100%;
  // justify-content: end;
  // padding-top: 87%;
  // padding-bottom: 10%;
  // align-items: end;
  // overflow-y: hidden;
  position: absolute;
  // top: 650px;
  // padding-top: 87%;
  // align-items: flex-end;
  // text-align: end;
  // justify-content: end;
  // flex: 1;
  // width: 100%;

  // flex-grow: 1;
  // flex-shrink: 0;
  // width: auto;
  // top: 86%;
  // bottom: 66px;
  bottom: 0px;
  top: 0px;
  left: 0px;
  right: 0px;
  padding-left: 8%;
  padding-right: 8%;
  // left: 32px;
  // right: 32px;
  // left: 50%;
  // transform: translate(-50%, 0);
}

.imgVariantBar {
  width: 100%;
  height: 100%;
  // box-shadow: 0 0 60px 0 rgba(19, 18, 18, 0.1) inset;

  // background: linear-gradient(
  //   180deg,
  //   rgba(0, 0, 0, 0) 72.07%,
  //   rgba(0, 0, 0, 0.9) 100%
  // ) !important;

  object-fit: cover;
}

// TEXT

.textTitel {
  color: #fff;
  // font-size: 72px;
  // font-weight: 400;
  @include textTitelContent;
}

.textDisript {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}

.textContentVarintBar {
  color: #fff;
  @include textSizePhotoAtmosphere;
}
